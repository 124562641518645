"use client";

import { useAppContext } from "@/context/AppContextProvider";
import Link from "next/link";
import { FC } from "react";
import Image from "next/image";
import "./style.css";


const LoginHeader: FC = () => {
  const { visableMode } = useAppContext();
  return (
    <header
      className="fixed top-2 w-full min-w-screen-sm flex justify-between bg-opacity-50 settingArea"
    >
      <div
        className={`ml-2 ${visableMode.isShow ? "fade-in" : "fade-out"}`}
      >
        <Link className="text-white" href="/">
          <h1 className="max-w-28">
            <Image
              alt="picsle logo"
              src="/picsle_logo.svg"
              width={300}
              height={300}
              style={{ height: "auto" }}
              fill={false}
              priority={false}
            />
          </h1>
        </Link>
      </div>
    </header >
  );
};

export default LoginHeader;
