'use client'

import React from 'react'
import { Button } from './ui/button'
import { LocaleConstants } from '@/lib/react/i18n/constants/LocaleConstants';
import { useTranslation } from '@/lib/react/i18n';
import { LocaleApi } from '@/restapi/locale';
import { useSession } from 'next-auth/react';


type CustomComponentProps = React.ComponentProps<'div'> & {
	customProp?: string;
}

const EnButton: React.FC<CustomComponentProps> = ({ customProp, className, style, ...props }) => {
	const { setLocale, locale, t } = useTranslation();
	const { data: session } = useSession();
	const user = session?.user;

	const clickEn = async () => {
		setLocale('en');
		if (typeof window === "undefined") return;
		window.localStorage.setItem(LocaleConstants.LOCALSTORAGE_KEYS.DEFAULT_LOCAL, 'en');

		try {
			if (user) {
				await LocaleApi.changeLanguage({ locale: 'en', userId: user.id, });
			}
		} catch (error: any) {
			alert(t('Language change failed'));
		}
	}

	return (
		<Button
			className={className}
			style={locale === 'en'
				? {
					backgroundColor: 'rgb(209 213 219 / var(--tw-bg-opacity))',
					color: '#000'
				}
				: {
					backgroundColor: 'rgb(100 100 100 / var(--tw-bg-opacity))',
					color: '#ffffff'
				}
			}
			onClick={clickEn}>EN</Button>
	)
}

export default EnButton
