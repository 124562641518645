import { BackendURLContantas, URLConstants } from "@/constants/URLConstants";
import { axios } from "@/lib/axios";
import { Locale } from "@/lib/react/i18n/types/locale";

export const LocaleApi = {
  changeLanguage: async (request: { locale: Locale; userId?: string; }) => {
    try {
      const response = await axios.post(
        BackendURLContantas.LOCALE.SET_LOCALE.PATH_NAME,
        request,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true, // セッションを有効にする
        }
      );
      // console.log('Response:', response.data);
    } catch (error) {
      console.error('Error changing locale:', error);
    }
  },
}