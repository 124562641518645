"use client";

import EnButton from "@/components/EnButton";
import Footer from "@/components/Footer";
import JpButton from "@/components/JpButton";
import LoginHeader from "@/components/organisms/header/LoginHeader";
import { URLConstants } from "@/constants/URLConstants";
import { useTranslation } from "@/lib/react/i18n";
import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";

export default function AccessDeniedPage() {
  const { t, locale } = useTranslation();

  // 遷移しようとした元のURLを取得
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get("callbackUrl");

  // URLデコードして元の形に戻す
  const decodedCallbackUrl = callbackUrl ? decodeURIComponent(callbackUrl) : "/"


  return (
    <div className='flex flex-col px-4 pc-container'>
      <LoginHeader />
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-6 my-10">
        {/* ロゴ */}
        <div className="mb-6">
          <Image
            className="object-contain"
            alt="picsle logo"
            src="/picsle_logo.svg"
            width={300}
            height={300}
            style={{ width: "100%", height: "auto" }}
            sizes="(max-width: 768px) 100vw, 300px"
            fill={false}
            priority
          />
        </div>
        <div className="flex justify-between mb-4 p-2 min-w-[300px]" >
          <EnButton className={`w-[120px] ${locale === 'en' && 'bg-gray-300 text-black'}`} />
          <JpButton className="w-[120px]" />
        </div>

        {/* 説明テキスト */}
        <p className="text-center text-lg mb-4">
          {t('Let\'s get started')}
        </p>
        {/* ボタン */}
        <div className="w-full max-w-sm flex flex-col gap-4">
          <Link href={`${URLConstants.signin.pathname}?callbackUrl=${decodedCallbackUrl}`}>
            <button className="w-full bg-indigo-600 text-white font-semibold py-3 rounded-md">
              {t('Login')}
            </button>
          </Link>
          <Link href={`${URLConstants.signup.pathname}?callbackUrl=${decodedCallbackUrl}`}>
            <button className="w-full bg-purple-600 text-white font-semibold py-3 rounded-md">
              {t('Create Account')}
            </button>
          </Link>
          <div className="my-2">
            <p className="text-xs text-gray-500">
              {t("By registering, you agree to")}{" "}
              <Link className="text-[#1DA1F2]" href="/terms-of-service.pdf">
                {t("the Terms of Use")}
              </Link>{" "}
              {t("and")}{" "}
              <Link className="text-[#1DA1F2]" href="/picsle-privacy-policy.pdf">
                {t("Privacy Policy.")}
              </Link>
            </p>
            <div className="my-2 flex justify-center">
              <Link className="text-[#1DA1F2]" href="/password-reset">
                <p className="text-sm">{t("Forgot your password?")}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}